import { environment } from './../../../environments/environment';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { tap, catchError, map } from 'rxjs/operators';
import moment from 'moment';

export let InjectorInstance: Injector;

@Injectable({
  providedIn: 'root',
})
export class Service {
  api: string = environment.api;
  url: string;
  constructor(private http: HttpClient, @Inject(URL) _url: string) {
    this.url = `${this.api}api/${_url}`;
  }

  // create resources

  get(queryString: any = ''): Observable<any> {
    return this.http
      .get(`${this.url}${queryString ? '?' + queryString : ''}`)
      .pipe(
        tap((stories: any) => {
          return stories;
        }),
        catchError(this.handleError)
      );
  }

  post(resource: any) {
    return this.http.post(this.url, { ...resource, module: this.module }).pipe(
      map((response) => response),
      catchError(this.handleError)
    );
  }

  patch(resource: any) {
    return this.http
      .patch(this.url + '/' + resource.id, { ...resource, module: this.module })
      .pipe(
        map((response) => response),
        catchError(this.handleError)
      );
  }

  put(id: any, resource: any) {
    return this.http
      .put(this.url + '/' + id, { ...resource, module: this.module })
      .pipe(
        map((response) => response),
        catchError(this.handleError)
      );
  }

  show(id: number) {
    return this.http.get(this.url + '/detail/' + id).pipe(
      map((response) => response),
      catchError(this.handleError)
    );
  }

  find(id: number, qs: string = '') {
    return this.http.get(this.url + '/' + id + qs).pipe(
      map((response) => response),
      catchError(this.handleError)
    );
  }

  delete(id: number) {
    return this.http.delete(this.url + '/' + id).pipe(
      map((response) => response),
      catchError(this.handleError)
    );
  }

  uploadFile(data: FormData) {
    return this.http
      .post(this.api + 'api/uploadfile', { ...data, module: this.module })
      .pipe(
        map((response) => response),
        catchError(this.handleError)
      );
  }

  private handleError(error: Response) {
    if (error.status === 400) return throwError(error);

    if (error.status === 404) return throwError(error);

    return throwError(error);
  }

  showError(err: HttpErrorResponse) {
    try {
      if (Object.keys(err.error.message).length) {
        let msg: any = err.error.message;
        return msg.replace('Error:', '');
      } else {
        return err.message.replace('Error:', '');
      }
    } catch (error) {
      return 'Something went wrong';
    }
  }

  secondsToHms(d: number, inSecond: boolean = false) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60) + (inSecond ? 1 : 0);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : '';
    var mDisplay = m > 0 ? m + (m == 1 ? ' minute ' : ' minutes') : '';
    var sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';
    // return hDisplay + mDisplay + sDisplay;
    if (!inSecond && m > 1) return mDisplay;

    return sDisplay;
  }

  humanizeDuration(timeInSecond: number) {
    let timeInMillisecond = timeInSecond * 1000;
    let result;
    if (timeInMillisecond) {
      if (
        (result = Math.round(
          timeInMillisecond / (1000 * 60 * 60 * 24 * 30 * 12)
        )) > 0
      ) {
        //year
        result = result === 1 ? result + ' Year' : result + ' Years';
      } else if (
        (result = Math.round(timeInMillisecond / (1000 * 60 * 60 * 24 * 30))) >
        0
      ) {
        //months
        result = result === 1 ? result + ' Month' : result + ' Months';
      } else if (
        (result = Math.round(timeInMillisecond / (1000 * 60 * 60 * 24))) > 0
      ) {
        //days
        result = result === 1 ? result + ' Day' : result + ' Days';
      } else if (
        (result = Math.round(timeInMillisecond / (1000 * 60 * 60))) > 0
      ) {
        //Hours
        result = result === 1 ? result + ' Hours' : result + ' Hours';
      } else if ((result = Math.round(timeInMillisecond / (1000 * 60))) > 0) {
        //minute
        result = result === 1 ? result + ' Minute' : result + ' Minutes';
      } else if ((result = Math.round(timeInMillisecond / 1000)) > 0) {
        //second
        result = result === 1 ? result + ' Second' : result + ' Seconds';
      } else {
        result = timeInMillisecond + ' Millisec';
      }
    }
    return result;
  }

  toLocale(date: string) {
    return moment(date).format('DD-MM-YYYY LT');
  }

  get module() {
    return localStorage.getItem('module') ?? 'celpip';
  }

  isCelpip() {
    return this.module === 'celpip';
  }

  isIelts() {
    return this.module === 'ielts';
  }
}
