<input
  type="file"
  class="file-input"
  (change)="onFileSelected($event)"
  #fileUpload
/>

<div class="file-upload">
  <button
    type="button"
    color="secondary"
    class="bg-gradient upload-btn d-flex btn border bg-gradient-light"
    (click)="fileUpload.click()"
  >
    <i class="fas fa-upload"></i> &nbsp;
    <span>
      {{
        config.displayFileName !== false && fileName
          ? fileName
          : false || (config.title ? config.title : "Upload file")
      }}
    </span>
    <mat-progress-bar
      class="progress-bar float-start"
      mode="determinate"
      [value]="uploadProgress"
      *ngIf="uploadProgress"
    ></mat-progress-bar>
  </button>
  <button
    mat-raised-button
    color="warn"
    class="ms-1"
    type="button"
    *ngIf="uploadProgress"
    (click)="cancelUpload()"
    aria-label="Example icon button with a filter list icon"
  >
    Cancel
  </button>
</div>
<p class="text-danger" *ngIf="uploadErr">{{ uploadErr }}</p>
