import { environment } from './../../../../environments/environment';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { HttpClient, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

export interface Config {
  title: string;
  fileName: string;
}

@Component({
    selector: 'lf-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    standalone: false
})
export class FileUploadComponent implements OnInit {
  @Input('data') data: any;
  @Input() config: any;

  @Output() onFileUploaded = new EventEmitter<any>();

  uploadErr: any = '';
  requiredFileType: string;
  env: any = environment;
  fileName = '';
  uploadProgress: number | null;
  uploadSub: Subscription;

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.fileName = this.config.fileName;
  }

  ngOnChanges() {
    this.fileName = this.config.fileName;
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    console.log(file, this.data);
    if (file) {
      this.fileName = file.name;
      const formData = new FormData();
      formData.append('file', file);
      let type = 'instrumental';

      if (this.data) {
        formData.append('id', this.data.id);
        formData.append('resource', this.data.resource);
        formData.append('column', this.data.column);
        if (this.data.hasOwnProperty('type') && this.data.type) {
          type = this.data.type;
        }
      }

      this.uploadErr = '';
      const upload$ = this.http
        .post(`${this.env.api}api/upload?type=${type}`, formData, {
          reportProgress: true,
          observe: 'events',
        })
        .pipe(finalize(() => this.reset()));

      this.uploadSub = upload$.subscribe(
        (event: any) => {
          if (event.type == HttpEventType.UploadProgress) {
            console.log(event.loaded, event.total);
            this.uploadProgress = Math.round(
              100 * (event.loaded / event.total)
            );
          }
          if (event.type == HttpEventType.Response) {
            this.onFileUploaded.emit(event);
          }
        },
        (err: HttpErrorResponse) => {
          this.uploadErr = err.error.message;
        }
      );
    }
  }

  cancelUpload() {
    this.uploadSub.unsubscribe();
    this.reset();
  }

  reset() {
    this.uploadProgress = null;
    this.uploadSub = new Subscription();
  }
}
