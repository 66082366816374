import { Notify } from './../../shared/services/notification.service';
import { environment } from './../../../environments/environment';
import { AuthService } from './../../auth/auth.service';
import { PackageService } from './../../admin/packages/package.service';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Package } from '../../admin/packages/package';

@Component({
    selector: 'app-upgrade-membership',
    templateUrl: './upgrade-membership.component.html',
    styleUrls: ['./upgrade-membership.component.scss'],
    standalone: false
})
export class UpgradeMembershipComponent {
  membershipPackages: any = [];

  constructor(
    public dialogRef: MatDialogRef<UpgradeMembershipComponent>,
    private packageService: PackageService,
    private auth: AuthService,
    private notify: Notify
  ) {
    packageService.getAllPackages('subscription-package').subscribe((res: any) => {
      let { data } = res;
      let findPackage: any[] = data.filter((pkg: any) => pkg.title == auth.user.package);
      console.log(findPackage);
      let packagePrice = 0;
      if(findPackage.length) {
        packagePrice = findPackage[0]['sellingPrice'];
      }

      this.membershipPackages = res.data.filter((_package: Package) => _package.sellingPrice > packagePrice);
    })
  }
  choosenPackage: any;
  selectedPackage(e: any) {
    this.choosenPackage = e.target.value;
  }

  api: string = environment.api;

  upgradePackage() {
    if(!this.choosenPackage) {
      this.notify.error("Select package to upgrade the membership");
      return
    }
    let id = this.choosenPackage.split("#")[0];
    let currency = this.choosenPackage.split("#")[1];
    console.log(this.choosenPackage.split("#"));
    window.location.href = `${this.api}package/buy/${id}?currency=${currency}`;
  }
}
