import { Injectable } from '@angular/core';
import { Service } from '../shared/services/service.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebinarService extends Service {

  apiUrl: string = `${environment.api}api/`;
  constructor(private httpClient: HttpClient) {
    super(httpClient, "webinar");
  }

  createOrder(_package: any) {
    return this.httpClient.post(`${this.apiUrl}package/buy/${_package.id}?type=webinar&currency=${_package.currency}`, {});
  }

  getInstructor() {
    return this.httpClient.get(`${this.api}api/instructor/list`);
  }

  copyWebinar(id: number, action: string) {
    return this.httpClient.post(`${this.apiUrl}webinar-copy/${id}`, { action });
  }
}
