import { environment } from './../../../environments/environment';
import { Notify } from './../../shared/services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Component } from '@angular/core';
import { AuthService } from '../auth.service';
import { HttpErrorResponse } from '@angular/common/http';
declare const window: any;
const countries = require('../../countries.json');

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.css'],
    standalone: false
})
export class SignupComponent {
  apiUrl: string = environment.api;
  countries: any[] = countries;

  public loginForm: FormGroup;
  public errors: any;
  public error: any;
  public loading: boolean = false;
  loginSubscription: Subscription = new Subscription();
  redirectUri: string = '';
  constructor(
    private fb: FormBuilder,
    public authService: AuthService,
    public router: Router,
    public notify: Notify,
    private activatedRoute: ActivatedRoute
  ) {
    this.loginForm = fb.group({
      name: new FormControl(),
      email: new FormControl(),
      mobileNumber: new FormControl(),
      dialCode: new FormControl('+91'),
      password: new FormControl(),
      confirmPassword: new FormControl(),
      city: new FormControl(),
      country: new FormControl('India'),
      termCondition: new FormControl(true),
      role: 'STUDENT',
    });
    activatedRoute.queryParams.subscribe((params: any) => {
      this.loginForm.patchValue(params);
      const redirect = sessionStorage.getItem("redirect") || ""
      if (Object.keys(params).length) {
        this.redirectUri = redirect;
      }
    });
  }

  countryChange(event: any) {
    let filterDialCode = countries.filter((country: any) => {
      return country.name == event.value;
    });
    if (filterDialCode.length) {
      this.loginForm.patchValue({
        dialCode: filterDialCode[0]['dial_code'],
      });
    }
  }

  onSubmit(value: any): void {
    this.errors = [];
    this.error = '';
    this.loading = true;
    const d = {
      fields: [
        {
          name: 'firstname',
          value: value.name,
        },
        {
          name: 'email',
          value: value.email,
        },
        {
          name: 'phone',
          value: value.dialCode + value.mobileNumber,
        },
        {
          name: 'country',
          value: value.country,
        },
        {
          name: 'source',
          value: 'website signup',
        },
      ],
    };
    value.module = this.authService.module;
    this.loginSubscription = this.authService.signup(value).subscribe(
      (res: any) => {
        this.loading = false;
        let user = res.data;
        window.analytics.track(`User Signed up (${user.email})`, {
          name: user.name,
          email: user.email,
          mobileNumber: `${value.dialCode}${value.mobileNumber}`,
        });
        sessionStorage.setItem("redirect", "")
        this.notify.success(
          'Congratulations! Your account has been created. Start exploring our platform now.'
        );
        this.authService.signup_data(d);
        this.authService
          .login(value.email, value.password)
          .subscribe((res: any) => {
            this.authService.setSession(res.data);
            setTimeout(() => {
              let elm: any = document.getElementById('appLogin');
              elm.submit();
            }, 3000);
          });
      },
      (err: HttpErrorResponse) => {
        console.log(err);
        this.loading = false;
        if (err.status == 422) {
          this.errors = err.error?.message;
          for (const key in this.errors) {
            if (Object.prototype.hasOwnProperty.call(this.errors, key)) {
              this.loginForm.controls[key].setErrors({ incorrect: true });
            }
          }
        } else {
          this.error = this.authService.showError(err);
        }
        if (this.error) {
          this.notify.error(this.error);
        }
      }
    );
  }

  socialLogin() {
    this.authService.socialLogin();
  }

  ngOnDestroy() {
    this.loginSubscription.unsubscribe();
  }
}
