import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class BearerTokenInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authService.getAccessToken();
    if (token) {
      let reqHead = req.headers.append('Authorization', 'Bearer ' + token);
      let _module = localStorage.getItem('module');

      if (_module === null) {
        _module = 'celpip';
      }

      req = req.clone({
        headers: reqHead,
        params: (req.params ? req.params : new HttpParams()).set(
          'module',
          _module
        ),
      });
    }
    return next.handle(req);
  }
}
