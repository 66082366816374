import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'dateFormat',
  standalone: false
})
export class DateFormatPipe implements PipeTransform {
  transform(value: string, ...args: string[]): unknown {
    let [format] = args;
    return moment(value).format(format);
  }
}
