<!-- <div class="dialog-header">
  <h3 class="dialog-title"> Test Result
    <i (click)="dialogRef.close()" class="far fa-times-circle float-end me-3 mt-1 fs-5 link"></i>
  </h3>
</div> -->
<div class="mat-dialog-container p-4">
  <p class="text-center mt-3 info-icon">
    <i class="fas fa-info-circle color-orange"></i>
  </p>

  <h1 class="text-center text-dark fs-4">
    You’re about to upgrade <strong> your’s </strong>
    account. Select the account tier to upgrade to.
  </h1>

  <select
    class="form-select w-75 mx-auto"
    (change)="selectedPackage($event)"
    *ngIf="membershipPackages && membershipPackages.length"
  >
    <option value="">Select membership</option>

    <option
      *ngFor="let _package of membershipPackages"
      [value]="_package.id + '#' + _package.currency"
    >
      {{ _package?.title }} (Rs. {{ _package.sellingPrice }})
    </option>
  </select>
  <p
    class="text-center"
    *ngIf="membershipPackages && !membershipPackages.length"
  >
    No package available for upgrade, you are already on higher package, Please
    contact support for further assistant.
  </p>

  <div class="action mt-4 text-center">
    <button (click)="dialogRef.close()" class="btn btn-outline-primary me-2">
      Cancel
    </button>
    <button
      class="btn brand-btn-primary"
      *ngIf="membershipPackages && membershipPackages.length"
      (click)="upgradePackage()"
    >
      Upgrade
    </button>
  </div>
</div>
