<div class="container-fluid p-0">
  <div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-4">
      <h4 class="text-center fw-semibold mt-2" *ngIf="user?.mobileNumber">
        <lf-spinner></lf-spinner>
        Loading...
      </h4>
      <mat-card class="my-4 p-3" *ngIf="user && !user?.mobileNumber">
        <form
          [formGroup]="loginForm"
          (ngSubmit)="onSubmit(loginForm.value)"
          method="post"
        >
          <div class="row">
            <div class="col mb-2">
              <table>
                <tr>
                  <td>
                    <img class="rounded-circle w-75" [src]="user.picture" />
                  </td>
                  <td>
                    <h3 class="fw-semibold">
                      {{ user.name }} <br />
                      <span class="text-muted">{{ user.email }}</span>
                    </h3>
                  </td>
                </tr>
              </table>

              <p>Sign-in from <b>google</b> fill the below information.</p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Country</mat-label>
                <!-- <input matInput type="text" placeholder="Enter country" formControlName="country"> -->
                <mat-select
                  formControlName="country"
                  (selectionChange)="countryChange($event)"
                >
                  <mat-option
                    [value]="country.name"
                    *ngFor="let country of countries"
                    selected
                  >
                    {{ country.name }} ({{ country.dial_code }})
                  </mat-option>
                </mat-select>
                <mat-hint *ngIf="errors" class="error p-0 text-danger">{{
                  errors?.country
                }}</mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Mobile number</mat-label>
                <input
                  matInput
                  type="text"
                  required
                  placeholder="Enter mobile number"
                  formControlName="mobileNumber"
                />
                <span matTextPrefix>
                  {{ $any(loginForm.get("dialCode")).value }} &nbsp; &nbsp;
                </span>
                <mat-error *ngIf="errors" class="text-danger"
                  >Please enter the contact number</mat-error
                >
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <button
                mat-raised-button
                [disabled]="loading"
                type="submit"
                class="w-100 mb-2"
                color="primary"
              >
                Save information
                <mat-spinner
                  color="accent"
                  *ngIf="loading"
                  class="float-end ms-1 mt-1"
                  [ngStyle]="{ width: '15px', height: '15px' }"
                >
                </mat-spinner>
              </button>
            </div>
          </div>
        </form>
      </mat-card>
    </div>
  </div>
</div>
