import { Component } from '@angular/core';

@Component({
    selector: 'app-loader-dialog',
    templateUrl: './loader-dialog.component.html',
    styleUrls: ['./loader-dialog.component.scss'],
    standalone: false
})
export class LoaderDialogComponent {

}
