<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content class="mat-typography">
  <h3>{{ data.description }}</h3>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="confirm()" color="primary" cdkFocusInitial>
    Yes
  </button>
</mat-dialog-actions>
