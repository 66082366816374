import { AuthService } from './../../../auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from './../../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Notify } from '../../services/notification.service';

@Component({
    selector: 'app-account-verification',
    templateUrl: './account-verification.component.html',
    styleUrls: ['./account-verification.component.scss'],
    standalone: false
})
export class AccountVerificationComponent implements OnInit {
  loading: boolean = false;

  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private notify: Notify,
  ) {
    
  }

  ngOnInit() {
    this.loading = true;
    this.activatedRoute.params.subscribe((params) => {
      let token = params['token'];
      this.http.post(`${environment.api}api/verify/${token}`, {}).subscribe(
        (res: any) => {
          this.notify.success(res.message);
          setTimeout(() => {
            window.location.href = '/';
          }, 3 * 1000);
          this.loading = false;
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          this.notify.error(err.error.message);
          this.loading = false;
        }
      );
    });
  }
}
