import { environment } from './../../../environments/environment';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
  standalone: false
})
export class ForgotPasswordComponent {
  apiUrl: string = environment.api;
  public loginForm: FormGroup;
  public error: string = '';
  public message: string = '';
  public loading: boolean = false;
  loginSubscription: Subscription = new Subscription();

  constructor(private fb: FormBuilder, public authService: AuthService, public router: Router) {
    this.loginForm = fb.group({
      email: new FormControl(),
    });
  }

  onSubmit(value: any): void {
    this.error = '';
    this.message = '';
    this.loading = true;
    this.loginSubscription = this.authService
      .passwordResetLink(value.email)
      .subscribe(
        (res: any) => {
          this.loading = false;
          this.message = res.message;
        },
        (err: HttpErrorResponse) => {
          this.loading = false;
          if (err?.message.indexOf('Invalid') !== -1) {
            this.error = "Sending password reset link is failed, try again after some time."
          } else {
            if (err.status === 422) {
              this.error = err.error?.message.email.join(', ');
            } else {
              this.error = err.error?.message;
            }
          }
        }
      );
  }

  socialLogin() {
    this.authService.socialLogin();
  }

  ngOnDestroy() {
    this.loginSubscription.unsubscribe();
  }
}
