import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AuthService } from '../auth.service';
import { Notify } from 'src/app/shared/services/notification.service';
import { filter, Subject, takeUntil } from 'rxjs';
import { forkJoin } from 'rxjs';

@Injectable()
export class AuthGuard {
  private destroy$ = new Subject<void>();

  constructor(private authService: AuthService, private activatedRoute: ActivatedRoute, private router: Router, private notify: Notify) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const _isValid = this.authService.isLoggedIn();
    if (!_isValid) {
      if (!['/', '/login'].includes(this.router.url)) {
        this.router.navigate(['/login'], {
          queryParams: { redirect_uri: window.location.href },
        });
      } else {
        this.router.navigate(['/login'])
      }
    }

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.destroy$)
    ).subscribe({
      next: (res: any) => {
        if (res.url != '/my-account') {
          forkJoin({
            response: this.authService.getSettings(),
          }).subscribe({
            next: (results: any) => {
              console.log('All API calls succeeded!', results);
              const user = results.response.data
              if (Object.keys(user).length) {
                this.authService.setSettings({ data: user });
              }
              // check if current url is my-account to prevent infinite loading
              if (!user?.mobileNumber) {
                this.notify.setDurations(20000);

                this.notify.error("Please fill the mobile number to continue");
                this.router.navigateByUrl("/my-account");
              }
            },
            error: (error) => {
              console.error('One or more API calls failed:', error);
            },
          });
        }
      }
    })
    setTimeout(() => {
      this.destroy$.next();
      this.destroy$.complete();
    }, 3000);
    return true;
  }
}
