import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Service } from '../shared/services/service.service';

@Injectable({
  providedIn: 'root'
})
export class MyAccountService extends Service {

  constructor(private httpClient: HttpClient) {
    super(httpClient, "self/update");
  }

  myAccount(id: number) {
    return this.httpClient.get(`${this.api}api/users/${id}`);
  }
}
